.word {
    box-sizing: border-box;
    font-size: 80px;
    padding: 24px;
    text-align: center;
    width: 100%;
}

.dict-link {
    color: #000;
    text-decoration: none;
}

.dict-link:hover {
    text-decoration: underline;
}

.definition {
    font-size: 24px;
    margin: 16px 0;
    text-align: center;
    width: 100%;
}

.buttons {
    text-align: center;
    width: 100%;
}

.buttons button {
    margin: 4px;
    padding: 4px 8px;
}