label {
    display: block;
}

.label {
    font-size: 20px;
}

.cards {
    height: 300px;
    font-size: 20px;
    width: 300px;
}