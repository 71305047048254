html, body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  height: 100%;
}

.top-nav {
  background-color: #333;
  box-sizing: border-box;
  display: flex;
  line-height: 40px;
  padding: 0 16px;
  width: 100%;
}

.title {
  color: #fff;
  flex-grow: 1;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.content {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 8px;
}

.back {
  color: #000;
  text-decoration: none;
}