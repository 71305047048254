.page-header {
    display: flex;
    line-height: 40px;
    margin: 16px 0;
}

.page-title {
    flex-grow: 1;
    margin: 0;
}

.header-action {
    color: #0000ee;
    margin-left: 12px;
}

.sets {
    border-collapse: collapse;
    width: 100%;
}

.sets th {
    text-align: left;
    padding: 4px;
}

.col-label {
    width: 100%;
}

.col-cards {
    white-space: nowrap;
}

.col-edit {
    white-space: nowrap;
}

.cell-link {
    display: block;
    color: #000;
    padding: 4px;
    text-decoration: none;
}

.link-edit {
    color: #0000ee;
}

.sets tbody tr:hover {
    background: #eee;
}